<template>
    <form-text-input
        size="sm"
        v-model="value"
        @keyup.enter="onSaveName"
        :disabled="loading || disabled"
        :error-message="errorMessage"
        v-bind="$attrs"
    />
    <base-icon
        variant="success"
        name="line-icons:general:check-circle"
        size="lg"
        @click.stop="onSaveName"
    />
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
const emit = defineEmits(['on-submit', 'update:modelValue'])

const props = defineProps({
    modelValue: {
        type: String,
        default: null,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    errorMessage: {
        type: String,
        default: null,
    },
})

const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    },
})

const onSaveName = () => {
    emit('on-submit', unref(value))
}
</script>
